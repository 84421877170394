@media all and (max-width: 767px) {
  .answer-container {
    display: flex;
    margin-top: 25px;
    align-items: center;
    padding: 0 20px;
    flex-direction: column;
  }
  .questions-text {
    font-size: 25px;
    margin-top: 20px;
    font-family: MontserratRegular;

  }
  .answer-text {
    font-size: 1.0625rem;
    font-family: MontserratRegular;
    line-height: 22px;
    letter-spacing: -0.41px;
    margin-top: 10px;
  }
}