//icons
$icon-logo: '/static/icons/icon-logo.png';
$icon-hide-eye: '/static/icons/icon-hide.png';
$icon-eye: '/static/icons/icon-eye.png';
$icon-lock: '/static/icons/icon-lock.svg';
$icon-flag-uk: '/static/icons/icon-flag-uk.svg';
$icon-flag-no: '/static/icons/icon-flag-no.svg';
$icon-flag-ua: '/static/icons/icon-flag-ua.svg';
$icon-flag-ru: '/static/icons/icon-flag-ru.svg';

//fonts
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url('/static/fonts/Montserrat-SemiBold.ttf') format("truetype")
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url('/static/fonts/Montserrat-Medium.ttf') format("truetype")
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url('/static/fonts/Montserrat-Regular.ttf') format("truetype")
}

$montserrat-semi-bold: 'Montserrat-SemiBold';
$montserrat-medium: 'Montserrat-Medium';
$montserrat-regular: 'Montserrat-Regular';

//colors
$light-grey: #ebecef;
$light-grey-border: #dcdcdc;
$light-grey-placeholder: #9b9b9b;
$dark-grey: #2f2f30;
$slate-grey: #504f53;
$white-main: #ffffff;
$danger: #e35d68;

