@import '../../../../assets/styles/variables';

.language-switcher {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.flag {
  width: 30px;
  height: 30px;
  cursor: pointer;
  opacity: 0.4;

  &__active {
    opacity: 1;
  }

  &__en {
    background: url($icon-flag-uk) no-repeat center center;
  }

  &__no {
    background: url($icon-flag-no) no-repeat center center;
  }

  &__ua {
    background: url($icon-flag-ua) no-repeat center center;
  }

  &__ru {
    background: url($icon-flag-ru) no-repeat center center;
  }

}
