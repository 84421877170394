@import '../../../assets/styles/variables';

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    display: inline-block;
    width: 120px;
    height: 120px;

    &:after {
      content: " ";
      display: block;
      width: 96px;
      height: 96px;
      margin: 8px;
      border-radius: 50%;
      border: 9px solid $white-main;
      border-color: $white-main transparent $white-main transparent;
      animation: spin-loader 1200ms linear infinite;
    }

  }

  @keyframes spin-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

