@import '../../../../assets/styles/variables';

.restore-password {
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  background-color: $light-grey;
  background-size: cover;
  overflow: hidden;

  &__logo {
    background: url($icon-logo) no-repeat;
    width: 126px;
    height: 112px;
    display: flex;
    align-items: center;
  }

  &__info {
    text-align: center;
    margin-top: 32px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.41px;
    font-family: $montserrat-semi-bold;
    color: $dark-grey;
  }

  &__form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    flex-direction: column;

    .error-wrap {
      position: absolute;
      bottom: -80px;
      p {
        color: $danger;
        text-align: center;
        font-family: $montserrat-medium;
      }
    }

  }

  &__btn {
    width: 192px;
    height: 48px;
    margin-top: 24px;
    background-color: $slate-grey;
    color: $white-main;
    font-size: 14px;
    font-family: $montserrat-medium;
    line-height: 18px;
    letter-spacing: -0.41px;
    text-transform: uppercase;
    border-radius: 48px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 100ms linear;
    &:hover {
      background-color: lighten($slate-grey, 20);
    }
    &:disabled {
      cursor: not-allowed;
      background-color: lighten($slate-grey, 20);
      color: lighten($slate-grey, 40);
    }
  }

}


@media (max-width: 380px) {
  .restore-password {
    &__info {
      margin-top: 10px;
    }
    &__form {
      margin-top: 20px;
    }
    &__btn {
      margin-top: 10px;
    }
  }
}



