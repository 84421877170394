@import '../../../../../assets/styles/variables';

.input-wrap {
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-columns: 30px auto 30px;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 48px;
  margin-bottom: 32px;
  padding: 0 10px;
  border-radius: 48px;
  border: solid 1px $light-grey-border;
  background-color: $white-main;

  &:focus-within {
    border: solid 2px $light-grey-placeholder;
    &.error {
      border: solid 2px $light-grey-placeholder;
    }
    span.input-error {
      display: none;
    }
  }

  &.error {
    border: solid 1px $danger;
  }

  input {
    min-width: 230px;
    padding: 10px 0;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: $montserrat-regular;
    letter-spacing: -0.22px;
    color: $dark-grey;
    height: 20px;
    &::placeholder {
      color: $light-grey-placeholder;
      font-family: $montserrat-regular;
    }
  }

  span.icon-lock {
    background: url($icon-lock) no-repeat center center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  span.icon-eye{
    background: url($icon-hide-eye) no-repeat center center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &.visible {
      background: url($icon-eye) no-repeat center center;
    }
  }

  span.input-error {
    box-sizing: border-box;
    width: auto;
    font-size: 11px;
    font-family: $montserrat-regular;
    line-height: 18px;
    position: absolute;
    left: 18px;
    bottom: -18px;
    color: $danger;
  }

}
